<template>
  <div class="resident">
    <HeaderMenu />
    <div class="container-fluid py-4">
      <h1 class="h5 fw-bold mb-3">{{ $t('__notifyReport') }}</h1>
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="exportReport">
            <div class="mb-3">
              <label class="form-label fw-bold">{{ $t('__reportType') }}</label>
              <div class="d-flex align-items-center flex-wrap">
                <div class="form-check form-check-inline">
                  <input
                    v-model="from.report_type"
                    class="form-check-input"
                    type="radio"
                    id="allResident"
                    value="all"
                    name="report_type"
                    required
                  />
                  <label class="form-check-label" for="allResident">{{
                    $t('__allResident')
                  }}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="from.report_type"
                    class="form-check-input"
                    type="radio"
                    id="oneResident"
                    value="one"
                    name="report_type"
                    required
                  />
                  <label class="form-check-label" for="oneResident">{{
                    $t('__oneResident')
                  }}</label>
                </div>
              </div>
            </div>
            <div v-if="from.report_type === 'one'" class="mb-3">
              <label for="bedNo" class="form-label fw-bold">{{
                $t('__residentName')
              }}</label>
              <div class="col-sm-6 col-md-4">
                <select class="form-select" v-model="from.id" required>
                  <option :value="null">{{
                    $t('__openThisSelectMenuResident')
                  }}</option>
                  <template v-for="data in resident">
                    <option
                      :key="data.id"
                      :value="data.id"
                      v-if="data.name"
                    >
                      {{
                        ((data.device === null) ? '' : data.device.bed_number) + " / " + data.name
                      }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label fw-bold">{{ $t('__date') }}</label>
              <div class="d-flex align-items-center flex-wrap">
                <div class="d-inline-block pe-sm-4 mb-2">
                  <VueCtkDateTimePicker
                    :label="$t('__startDate')"
                    v-model="from.start_date"
                    only-date
                    format="YYYY-MM-DD"
                    formatted="YYYY-MM-DD"
                    color="#2CBDC0"
                    button-color="#2CBDC0"
                    :max-date="new Date().format('yyyy-MM-dd')"
                    :min-date="new Date().addDays(-29).format('yyyy-MM-dd')"
                  />
                  <div class="text-danger" v-if="is_invalid.start_date">
                    {{ feedback_message.start_date }}
                  </div>
                </div>
                <div class="d-inline-block pe-sm-4 mb-2">
                  <VueCtkDateTimePicker
                    :label="$t('__endDate')"
                    v-model="from.end_date"
                    only-date
                    format="YYYY-MM-DD"
                    formatted="YYYY-MM-DD"
                    color="#2CBDC0"
                    button-color="#2CBDC0"
                    :max-date="new Date().format('yyyy-MM-dd')"
                    :min-date="from.start_date"
                  />
                  <div class="text-danger" v-if="is_invalid.end_date">
                    {{ feedback_message.end_date }}
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary rounded-pill">
              {{ $t('__export') }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenu from '@/components/layout/HeaderMenu.vue'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import i18n from '@/lang/lang.js'
import { resident } from '@/http/api/resident.js'
import { mapState, mapMutations } from 'vuex'
import { exportReport } from '@/http/api/export.js'

export default {
  name: 'ReportNotifyRecord',
  components: { HeaderMenu, VueCtkDateTimePicker },
  data () {
    return {
      resident: null,
      exportTaskId: null,
      from: {
        id: null,
        report_type: 'all',
        start_date: new Date().format('yyyy-MM-dd'),
        end_date: new Date().format('yyyy-MM-dd')
      },
      is_invalid: {
        start_date: false,
        end_date: false,
        data_type: false
      },
      feedback_message: {
        start_date: i18n.t('__dateIsRequired'),
        end_date: i18n.t('__dateIsRequired'),
        data_type: i18n.t('__atLeastOneCheckboxMustBeSelected')
      }
    }
  },
  computed: {
    ...mapState(['token'])
  },
  watch: {
    'token.headers.Authorization' (data) {
      if (data) {
        this.getResident()
      }
    },
    is_invalid: {
      handler: function () {
        setTimeout(() => {
          this.is_invalid = {
            start_date: false,
            end_date: false,
            data_type: false
          }
        }, 5000)
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    getResident () {
      const vm = this
      resident.get('', vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          const data = res.data.data
          vm.resident = data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    exportReport () {
      const vm = this

      if (!vm.from.start_date) {
        vm.is_invalid.start_date = true
        return
      }
      if (!vm.from.end_date) {
        vm.is_invalid.end_date = true
        return
      }
      vm.Loading()
      if (vm.from.report_type === 'all') {
        exportReport
          .allNotifyReport(
            {
              started_at: vm.from.start_date,
              ended_at: vm.from.end_date
            },
            vm.token
          )
          .then(res => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.exportTaskId = res.data.taskId
              vm.exportFile()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg
              })
              vm.Alerted()
            }
          })
      } else {
        exportReport
          .singleNotifyReport(
            vm.from.id,
            {
              started_at: vm.from.start_date,
              ended_at: vm.from.end_date
            },
            vm.token
          )
          .then(res => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.exportTaskId = res.data.taskId
              vm.exportFile()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg
              })
              vm.Alerted()
            }
          })
      }
    },
    exportFile () {
      const vm = this
      exportReport.getExportFile(vm.exportTaskId, vm.token).then(res => {
        if (res.status <= 201) {
          switch (res.data.status) {
            case 'failed':
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg
              })
              vm.Alerted()
              vm.Loaded()
              break
            case 'running':
              setTimeout(() => {
                vm.exportFile()
              }, 3000)
              break
            case 'success':
              window.open(
                /https/.test(res.data.path)
                  ? res.data.path
                  : window.location.origin + res.data.path,
                '_blank'
              )
              vm.Loaded()
              vm.step = 2
              break
          }
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg
          })
          vm.Alerted()
        }
      })
    }
  },
  created () {},
  mounted () {
    if (this.token.headers.Authorization) {
      this.getResident()
    }
  }
}
</script>

<style lang="scss">
.resident {
  .card {
    position: relative;
    &-body {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('~@/assets/images/icon.svg');
      background-repeat: no-repeat;
      background-position: calc(100% + 20px) calc(100% + 20px);
      background-size: auto;
      opacity: 0.1;
      z-index: 0;
    }
  }
}
</style>
